import React from "react";
import { IoClose } from "react-icons/io5"

const InfoModal = ({ playClickEffect, setToggle, disableCloseButton, title, text }) => {

  const handleCloseClick = (e) => {
    playClickEffect();
    e.stopPropagation();
    setToggle(false);
  }

  return (
    <div className="info-modal-background">
      <div className="info-modal">
        {!disableCloseButton && <button onClick={handleCloseClick} className="close-button"><IoClose /></button>}
        <h2>{title}</h2>
        <div className="info-container">
          <p>{text}</p>
        </div>
      </div>
    </div>
  );
}

export default InfoModal;
