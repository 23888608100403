import React, { useCallback } from "react";
import PlayerIndicator from "./PlayerIndicator";
import EnemyIndicator from "./EnemyIndicator";
import BaseIndicator from "./BaseIndicator";

const MiniMap = ({
  bases,
  players,
  isFirstPlace,
  playerPosition,
  ping,
  width,
  seeAllItem,
  socket
}) => {
  const calculatePosition = useCallback((position, width) => ({
    top: width >= 1250 ? (position[1] / 50) - 4 : (position[1] / 100) - 4,
    left: width >= 1250 ? (position[0] / 50) - 4 : (position[0] / 100) - 4
  }), []);

  const filteredBases = bases?.filter(base => Object.values(base)[0].claimedBy === socket.id) ?? [];
  const filteredPlayers = players?.filter(player => Object.keys(player)[0] !== socket.id) ?? [];

  return (
    <>
      <div className="ping-display">PING: {ping}ms</div>
      <div className="minimap">
        {playerPosition && (
          <PlayerIndicator
            isFirstPlace={isFirstPlace}
            position={playerPosition}
            width={width}
            calculatePosition={calculatePosition}
          />
        )}
        {seeAllItem &&
          filteredPlayers.map(player => (
            <EnemyIndicator key={Object.keys(player)[0]} player={player} width={width} calculatePosition={calculatePosition} />
          ))}
        {filteredPlayers.map(player =>
          Object.values(player)[0].isFirstPlace ? (
            <PlayerIndicator
              key={Object.keys(player)[0]}
              isFirstPlace
              position={Object.values(player)[0].pos}
              width={width}
              calculatePosition={calculatePosition}
            />
          ) : null
        )}
        {filteredBases.map(base => (
          <BaseIndicator key={Object.keys(base)[0]} base={base} width={width} calculatePosition={calculatePosition} />
        ))}
      </div>
    </>
  );
};

export default MiniMap;
