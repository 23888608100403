import React from "react";
import { useTranslation } from "react-i18next";

const ServerSelector = ({servers, selectedServer, setSelectedServer }) => {

  const { t, i18n } = useTranslation();
  
  const handleChange = (event) => {
    setSelectedServer(servers.find(server => server.name === event.target.value));
  };

  return (
    <div className="server-selector">
      <label htmlFor="server">{t('region')}: </label>
      <select value={selectedServer.name} onChange={handleChange}>
        {servers.map(server => (
          <option key={server.name} value={server.name}>
            {server.name}
          </option>
        ))}
      </select>
    </div>
  );
}

export default ServerSelector;
