import React from 'react';

const Player = ({ moveX, moveY, score, isFirstPlace, shieldItem, rotation, playerImage, username }) => {
  const scale = score >= 5000 ? 5 : 1 + Math.pow(score, 0.5) / 21;
  const playerStyle = {
    transform: `translate(${moveX}px, ${moveY}px) scale(${scale})`,
  };

  const playerImageClass = shieldItem ? 'player-image shield-item' : 'player-image';
  const playerImageStyle = {
    transform: `rotate(${rotation}deg)`,
    backgroundImage: `url(${playerImage})`,
  };

  const playerImageElement = (
    <div
      className={isFirstPlace ? `${playerImageClass} queen-bee` : playerImageClass}
      style={playerImageStyle}
    ></div>
  );

  return (
    <div className="player" style={playerStyle}>
      {playerImageElement}
      <p className="username">{username}</p>
    </div>
  );
};

export default Player;
