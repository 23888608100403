import React from 'react';
import { HiSpeakerWave, HiSpeakerXMark } from 'react-icons/hi2';

const ToggleAudio = ({ audioToggle, gameplayDisplay, playClickEffect, setAudioToggle }) => {
  const handleClickAudio = () => {
    playClickEffect();
    setAudioToggle((prevValue) => !prevValue);
  };

  return (
    <div
      onClick={handleClickAudio}
      className={gameplayDisplay ? 'toggle-audio gameplay' : 'toggle-audio'}
    >
      {audioToggle ? <HiSpeakerWave /> : <HiSpeakerXMark />}
    </div>
  );
};

export default ToggleAudio;
