import React, { useMemo, useState, useCallback, useEffect } from "react";
import ToggleAudio from "./ToggleAudio";
import FooterSection from "./FooterSection";
import InfoModal from "./InfoModal";
import ServerSelector from "./ServerSelector";
import { ImArrowLeft2, ImArrowRight2 } from 'react-icons/im';
import { MdOutlineFullscreen, MdContentCopy } from 'react-icons/md';
import { BiMoviePlay } from 'react-icons/bi';
import { HiShare } from 'react-icons/hi';
import AdinPlayAd from "./AdInPlayAd";
import { useTranslation } from "react-i18next";

const cn = (...args) => args.filter(Boolean).join(" ");

const LoginModal = (props) => {
  const { t, i18n } = useTranslation();
  const [copied, setCopied] = useState(false);
  const [showCopied, setShowCopied] = useState(false);

  const handleReviveClick = useCallback(() => {
    if (typeof window.aiptag.adplayer !== 'undefined') {
      window.aiptag.adplayer.startPreRoll();
    } else {
      console.log("Rewarded Ad Could not be loaded, load your content here");
    }
  });
  

  useEffect (() => {
  if (props.adCompleted) {
    console.log("The ad has been completed, you can proceed.");
    // Load your content or proceed with your logic here
    props.handlePlayClick(props.adCompleted, Math.round(props.score / 2));
    props.setAdCompleted(false);
  }
}, [props.adCompleted]);

  // memoize honey and flower emojis arrays
  const honeyEmojis = useMemo(() => Array(props.claimedHives).fill("🍯"), [
    props.claimedHives,
  ]);
  const flowerEmojis = useMemo(() => Array(props.claimedFlowers).fill("🌻"), [
    props.claimedFlowers,
  ]);

  // memoize emojiRows array and only re-calculate when dependencies change
  const emojiRows = useMemo(() => {
    const allEmojis = [...honeyEmojis, ...flowerEmojis];
    return allEmojis.reduce((rows, emoji, index) => {
      if (index % 8 === 0) {
        rows.push([]);
      }
      rows[rows.length - 1].push(emoji);
      return rows;
    }, []);
  }, [honeyEmojis, flowerEmojis]);

  const text = `Hibee 🐝\nScore: ${props.score} Kills: ${props.kills}\n${emojiRows
    .map((row) => row.join(""))
    .join("\n")} \nPlay here: https://hibee.io`;

  const twitterUrl = `https://twitter.com/intent/tweet?url=https://hibee.io&text=%23Hibee%20%F0%9F%90%9D%0AScore:%20${props.score}%20Kills:%20${props.kills}%0A${emojiRows
    .map((row) => row.join(""))
    .join("%0A")}%0APlay%20here:`;

  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=https://hibee.io&quote=${encodeURIComponent(
    text
  )}`;

  const handleCopyShareScore = useCallback(() => {
    props.playClickEffect();
    navigator.clipboard.writeText(text);
    setCopied(true);
  }, [props.playClickEffect, text]);

  const handleClickCopyText = useCallback(() => {
    props.playClickEffect();
    navigator.clipboard.writeText(props.inviteCode).then(
      () => {
        console.log("Text copied to clipboard");
        setShowCopied(true);
        setTimeout(() => {
          setShowCopied(false);
        }, 1000);
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  }, [props.playClickEffect, props.inviteCode]);


  const handleClickHowToPlay = () => {
    props.playClickEffect();
    props.setHowToPlayToggle(true);
  }

  const handleClickInviteFriends = () => {
    props.playClickEffect();
    props.setInviteFriendsToggle(true);
    props.socket.emit("inviteLink");
  }

  const handleLanguageChange = useCallback((e) => {
    const newLanguage = e.target.value;
    props.setSelectedLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
  }, [props, i18n]);

  return (
    <div className={cn("login-modal", !props.isModalDisplayed && "hidden")}>
      <AdinPlayAd containerClass="left-banner-ad" adUnitId="hibee-io_160x600_1" />
      <AdinPlayAd containerClass="right-banner-ad" adUnitId="hibee-io_160x600_2" />
      <AdinPlayAd containerClass="bottom-banner-ad" adUnitId="hibee-io_970x250" />
      <button onClick={handleClickInviteFriends} className="invite-friends">{t('inviteFriendsTitle')}</button>
      <div className="modal-settings-container">
        <ToggleAudio playClickEffect={props.playClickEffect} gameplayDisplay={false} audioToggle={props.audioToggle} setAudioToggle={props.setAudioToggle} />
        <div className="fullscreen-toggle" onClick={props.handleFullscreen}><MdOutlineFullscreen /></div>
      </div>
      <select className="language-select" value={i18n.language.split('-')[0]} onChange={handleLanguageChange}>
        <option value="en">English</option>
        <option value="es">Español</option>
        <option value="fr">Français</option>
        <option value="de">Deutsch</option>
        <option value="ru">Русский</option>
        <option value="hi">हिन्दी</option>
        <option value="th">ไทย</option>
        <option value="vi">Tiếng Việt</option>
        <option value="zh">中文</option>
        <option value="jp">日本語</option>
        <option value="ko">한국어</option>
      </select>
      <div className={!props.justDied ? 'main-container' : 'main-container stats-screen'}>
        {!props.justDied && <div className="game-title"></div>}
        {props.justDied && <div className="stat-container">
          <div className="game-over-image"></div>
          <div><div className="time-stat"><p>{t('timeAliveStat')} </p><p>{props.timeAlive}</p></div><div className="hives-stat"><p>{t('hivesCapturedStat')} </p><p>{props.claimedHives}</p></div></div>
          <div><div className="flowers-stat"><p>{t('flowersCapturedStat')} </p><p>{props.claimedFlowers}</p></div><div className="kills-stat"><p>{t('beesKilledStat')} </p><p>{props.kills}</p></div></div>
          <div className="score-stat"><p>{t('scoreStat')} </p><p>{props.score}</p></div>
          <div className="share-your-score">
            <p>{t('shareYourScore')}</p>
            <div className="share-button-container">
              <a className="twitter" href={twitterUrl} target="_blank" />
              <div className="share" onClick={handleCopyShareScore}>{!copied ? <><HiShare /> {t('share')} </> : t('copied')}</div>
            </div>
            <div className="revive-button" onClick={handleReviveClick}>{t('revive')} <BiMoviePlay /></div>
          </div>
        </div>}
        <form className="input-container" onSubmit={e => props.handlePlayClick(e)}>
          <input autoFocus={true} value={props.username} onChange={(e) => props.setLocalPlayer(prev => {
          return {
            ...prev,
            username: e.target.value,
          }
        })} minLength={1} maxLength={10} required={true} className="username-input" placeholder={t('enterName')}></input>
          <button type="submit" className="play-button"><h1>{!props.justDied ? t('play') : t('playAgain')}</h1></button>
          <div className="character-selector">
            <div className="selector-arrow-left" onClick={() => props.handleLeftCharSelectClick()}><ImArrowLeft2 /></div>
            <div className="character-image" style={{ backgroundImage: `url(${props.playerImage})` }}></div>
            <div className="selector-arrow-right" onClick={() => props.handleRightCharSelectClick()}><ImArrowRight2 /></div>
          </div>
          {props.selectedServer && !props.justDied && <ServerSelector servers={props.servers} selectedServer={props.selectedServer} setSelectedServer={props.setSelectedServer} />}
        </form>
        <div className="sub-info-container">
          {!props.justDied && <div className="how-to-play-button" onClick={handleClickHowToPlay}>{t('howToPlay')}</div>}
          {!props.justDied && <a onClick={() => props.playClickEffect()} className="merch-store-banner" href="https://hibee.creator-spring.com/" target="_blank"></a>}
        </div>
      </div>
      {!props.justDied && <div className="social-share">
        <div className="share-image"></div>
        <div className="share-buttons-container">
          <a className="twitter-share" href="https://twitter.com/intent/tweet?text=Come%20and%20play%20https%3A//hibee.io/!" target="_blank" />
          <a className="facebook-share" href="https://www.facebook.com/sharer/sharer.php?u=https%3A//hibee.io/" target="_blank" />
        </div>
      </div>}
      <div className="social-accounts">
        <a className="discord" href="https://discord.gg/RphcEU9yRJ" target="_blank" />
        <a className="twitter" href="https://twitter.com/hibee_io" target="_blank" />
        <a className="reddit" href="https://reddit.com/r/hibeeio" target="_blank" />
      </div>
      <FooterSection
        playClickEffect={props.playClickEffect}
        policyToggle={props.policyToggle}
        setPolicyToggle={props.setPolicyToggle}
        creditsToggle={props.creditsToggle}
        setCreditsToggle={props.setCreditsToggle}
        setPartnersToggle={props.setPartnersToggle}
        partnersToggle={props.partnersToggle}
      />
      {props.policyToggle && <InfoModal
        playClickEffect={props.playClickEffect}
        setToggle={props.setPolicyToggle}
        title={t('privacyPolicyTitle')}
        text={<div>
          <p>{t('privacyPolicyLine1')}</p>

          <p>{t('privacyPolicyLine2')}</p>

          <p>{t('privacyPolicyLine3')}</p>

          <p>{t('privacyPolicyLine4')}</p>

          <p>{t('privacyPolicyLine5')}</p>

          <p>{t('privacyPolicyLine6')}</p>

          <p>{t('privacyPolicyLine7')}</p>

          <p>{t('privacyPolicyLine8')}</p>

          <p>{t('privacyPolicyLine9')}</p>

          <p>{t('privacyPolicyLine10')}</p>

          <p>{t('privacyPolicyLine11')}</p>

          <p>{t('privacyPolicyLine12')}</p>

          <p>{t('privacyPolicyLine13')}</p>

          <p>{t('privacyPolicyLine14')}</p>
        </div>}
      />}
      {props.creditsToggle && <InfoModal
        playClickEffect={props.playClickEffect}
        setToggle={props.setCreditsToggle}
        title={t('creditsTitle')}
        text={
          <div>
            <p>{t('creditsLine1')}</p>
            <p>{t('creditsLine2')}</p>
          </div>
        }
      />}
      {props.partnersToggle && <InfoModal
        playClickEffect={props.playClickEffect}
        setToggle={props.setPartnersToggle}
        title={t('partnersTitle')}
        text={
          <div>
            <a href="https://kevin.games/" target="_blank" title="Free Online Games">Kevin Games</a>
          </div>
        }
      />}
      {props.howToPlayToggle && <InfoModal
        playClickEffect={props.playClickEffect}
        setToggle={props.setHowToPlayToggle}
        title={t('howToPlayTitle')}
        text={
          <div>
            <h1>{t('howToPlayLine1')}</h1>
            <p>{t('howToPlayLine2')}</p>
            <h1>{t('howToPlayLine3')}</h1>
            <p>{t('howToPlayLine4')}</p>
            <h1>{t('howToPlayLine5')}</h1>
            <p>{t('howToPlayLine6')}</p>
            <h1>{t('howToPlayLine7')}</h1>
            <p><div className="item-image boost"></div>{t('howToPlayLine8')}</p>
            <p><div className="item-image x2"></div>{t('howToPlayLine9')}</p>
            <p><div className="item-image see-all"></div>{t('howToPlayLine10')}</p>
            <p><div className="item-image shield"></div>{t('howToPlayLine11')}</p>
          </div>
        }
      />}
      {props.inviteFriendsToggle && <InfoModal
        playClickEffect={props.playClickEffect}
        setToggle={props.setInviteFriendsToggle}
        title={t('inviteFriendsTitle')}
        text={
          <div>
            <p className="share-description">{t('inviteFriendsDescription')}</p>
            <div className="share-container">
              <div className="share-link">{props.inviteCode}</div>
              <p className={`copied-text ${showCopied ? "show" : ""}`}>{t('copiedText')}</p>
              <div onClick={handleClickCopyText} className="copy-text"><MdContentCopy /></div>
            </div>
          </div>
        }
      />}
      {props.showIsRoomFullModal && <InfoModal
        playClickEffect={props.playClickEffect}
        setToggle={props.setShowRoomIsFullModal}
        title={t('alert')}
        text={
          <div>
            <p>{t('serverIsFull')}</p>
          </div>
        }
      />}
    </div>
  );
}

export default React.memo(LoginModal);
