import React from "react";

const EnemyIndicator = React.memo(({ player, width, calculatePosition }) => (
  <div
    className="enemy-indicator"
    style={calculatePosition(Object.values(player)[0].pos, width)}
  ></div>
));

export default EnemyIndicator;
