import React from "react";

const BaseIndicator = React.memo(({ base, width, calculatePosition }) => {
  const baseInfo = Object.values(base)[0];
  const indicatorStyle = {
    ...calculatePosition(baseInfo.pos, width),
    animation: baseInfo.isBeingCaptured ? "blinkingBackgroundMapIndicator 1s infinite" : "none"
  };

  return (
    <div
      className={`${baseInfo.type}-indicator`}
      style={indicatorStyle}
    ></div>
  );
});

export default BaseIndicator;
