import React from "react";

const PlayerIndicator = React.memo(({ isFirstPlace, position, width, calculatePosition }) => (
  <div
    className={isFirstPlace ? "first-place-indicator" : "player-indicator"}
    style={calculatePosition(position, width)}
  ></div>
));

export default PlayerIndicator;
