import React, { useMemo } from "react";

const Item = ({ location, type }) => {
  const animationTime = useMemo(() => Math.random() * (3 - 1) + 1, []);

  const itemContainerStyle = {
    top: location[1],
    left: location[0],
  };

  const itemStyle = {
    animation: `float ${animationTime}s ease-in-out infinite`
  };

  return (
    <div className="item-container" style={itemContainerStyle}>
      <div className={`item ${type}`} style={itemStyle}></div>
      <div className="item-shadow"></div>
    </div>
  );
}

export default React.memo(Item);
