import React from "react";
import { useTranslation } from 'react-i18next';

const ScoreDisplay = (props) => {
  const { t } = useTranslation();

  return (
    <div className="score-display">
      <p>{t('score')} {props.score}</p>
    </div>
  );
}

export default ScoreDisplay;
