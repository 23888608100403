import React from "react";
import { useTranslation } from 'react-i18next';

const FooterSection = ({ playClickEffect, setPolicyToggle, setCreditsToggle, setPartnersToggle }) => {
  const { t } = useTranslation();

  const handleClick = (callback) => {
    playClickEffect();
    callback(true);
  };

  return (
    <>
      <div className="footer-section">
        <p onClick={() => handleClick(setPolicyToggle)}>{t('privacyPolicy')}</p>
        |
        <p onClick={() => handleClick(setCreditsToggle)}>{t('credits')}</p>
        |
        <p onClick={() => handleClick(setPartnersToggle)}>{t('partners')}</p>
      </div>
      <div className="version-number">Hibee Beta 1.0.2</div>
    </>
  );
}

export default FooterSection;
