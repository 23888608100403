import React, { useEffect } from 'react';

const AdinPlayAd = ({ adUnitId, containerClass }) => {
  useEffect(() => {
    const displayAd = () => {
      if (window.aipDisplayTag) {
        console.log('displaying ad: ', adUnitId);
        window.aipDisplayTag.display(adUnitId);
      }
    };
    const timeoutId = setTimeout(displayAd, 1000); // Delay the ad initialization by 1 second
    return () => {
      clearTimeout(timeoutId); // Clear the timeout when the component is unmounted
    };

  }, [adUnitId]);

  return (
    <div className={containerClass} id={adUnitId}></div>
  );
};

export default AdinPlayAd;
