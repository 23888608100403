import React, { useEffect, useState, useMemo } from "react";
import flowerRed from '../img/flowerRed.png';
import flowerWhite from '../img/flowerWhite.png';
import flowerBlue from '../img/flowerBlue.png';

const Base = ({ backgroundImage, claimed, claimedBy, socket, location, beingClaimed, type, userId }) => {
  const [background, setBackground] = useState('');

  useEffect(() => {
    switch (backgroundImage) {
      case 'red':
        setBackground(flowerRed);
        break;
      case 'white':
        setBackground(flowerWhite);
        break;
      default:
        setBackground(flowerBlue);
    }
  }, [backgroundImage]);

  const baseBackgroundColor = useMemo(() => {
    if (claimed && claimedBy === socket.id) {
      return 'rgb(0 128 0 / 50%)';
    } else if (claimed && claimedBy !== socket.id) {
      return 'rgb(255 0 0 / 50%)';
    } else {
      return 'rgb(128 128 128 / 50%)';
    }
  }, [claimed, claimedBy, socket.id]);

  return (
    <div className="base" style={{
      top: location[1],
      left: location[0],
      animation: beingClaimed ? 'blinkingBackground 1s infinite' : 'none',
      borderRadius: type === 'flower' ? '50%' : '0%',
      backgroundColor: baseBackgroundColor,
      WebkitClipPath: type === 'flower' ? 'polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%)' : 'none',
      clipPath: type === 'hive' ? 'polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%' : 'none',
      width: type === 'hive' ? '700px' : '400px',
      height: type === 'hive' ? '700px' : '400px'
    }}>
      {userId !== 'N/A' && <h2 className="claimed-user-id">{userId}</h2>}
      {type === 'hive' ? <div className="hive-image"></div> : <div className="flower-image" style={{ backgroundImage: `url('${background}')` }}></div>}
    </div>
  );
}

export default Base;
