import React, { useMemo } from "react";
import { useTranslation } from 'react-i18next';

const Leaderboard = ({ players, socket }) => {
  const { t } = useTranslation();

  const sortedPlayers = useMemo(() => {
    const filteredPlayers = players.map(player => Object.entries(player)[0][1])
      .filter(player => player.username.length > 0);
    return filteredPlayers.sort((a, b) => (a.score < b.score) ? 1 : -1);
  }, [players]);

  const curPlayer = useMemo(() => players.filter(player => Object.keys(player)[0] === socket.id)[0], [players, socket.id]);
  const curPlayerScore = curPlayer ? curPlayer[socket.id] : null;
  const curPlayerIndex = curPlayerScore ? sortedPlayers.findIndex(player => player.username === curPlayerScore.username) : -1;

  const displayTopPlayers = sortedPlayers.slice(0, 9);

  return (
    <div className="leaderboard">
      <h2 className="header">{t('leaderboard')}</h2>
      {displayTopPlayers.map((player, index) => (
        <div className={`player-name-container ${index === 0 ? "top-player" : ""} ${curPlayerScore && curPlayerScore.username === player.username ? "current-player" : ""}`}>
          <p>{index + 1}.</p>
          <p className={"player-name"}>{player.username}</p>
          <p className="player-score">{player.score}</p>
        </div>
      ))}
      {curPlayerIndex >= 0 && curPlayerIndex >= 9 && (
        <div className="player-name-container current-player">
          <p>{curPlayerIndex + 1}.</p>
          <p className="player-name">{curPlayerScore.username}</p>
          <p className="player-score">{curPlayerScore.score}</p>
        </div>
      )}
    </div>
  );
}

export default Leaderboard;
