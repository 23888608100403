import React, { useEffect, useState, useRef, useCallback } from 'react';
import io from 'socket.io-client';
import './i18n';
import i18n from './i18n';
import { useTranslation } from 'react-i18next';
import LoginModal from './components/LoginModal';
import Player from './components/Player';
import './style.css';
import honeybee from './img/honeybee.png';
import bumblebee from './img/bumblebee.png';
import wasp from './img/wasp.png';
import honeybeeQueen from './img/honeybee-queen.png'
import bumblebeeQueen from './img/bumblebee-queen.png'
import waspQueen from './img/wasp-queen.png'
import honeybeeCharacter from './img/honeybeeCharacter.png';
import bumblebeeCharacter from './img/bumblebeeCharacter.png';
import waspCharacter from './img/waspCharacter.png';
import backgroundImage from './img/map.png';
import Base from './components/Base';
import Item from './components/Item';
import Leaderboard from './components/Leaderboard';
import PlayerCount from './components/PlayerCount';
import MiniMap from './components/MiniMap';
import ScoreDisplay from './components/ScoreDisplay';
import { MdOutlineFullscreen } from 'react-icons/md';
import InfoModal from './components/InfoModal';
import ToggleAudio from "./components/ToggleAudio";
import hibeeTheme from './audio/hibee-theme.mp3';
import clickEffect from './audio/button-click-effect.mp3';
import pollenPickupEffect from './audio/pollen-item-sound-effect.mp3';
import firstPlaceEffect from './audio/first-place-sound-effect.mp3';
import boostPickupEffect from './audio/speed-boost-item-sound-effect.mp3';
import seeAllPickupEffect from './audio/see-all-item-sound-effect.mp3';
import shieldPickupEffect from './audio/shield-item-sound-effect.mp3';
import x2scorePickupEffect from './audio/x2score-item-sound-effect.mp3';
import deathEffect from './audio/death-sound-effect.mp3';
import claimHiveEffect from './audio/claim-hive-sound-effect.mp3';
import claimFlowerEffect from './audio/claim-flower-sound-effect.mp3';
import AdInPlayVideoAd from "./components/AdInPlayVideoAd";

// const socket = io('http://localhost:3030');

function App() {
  const [localPlayer, setLocalPlayer] = useState({
    shieldItem: false,
    seeAllItem: false,
    username: '',
    moveY: Math.round(Math.random() * (9500 - 0) + 0),
    moveX: Math.round(Math.random() * (9500 - 0) + 0),
    collidingWith: false,
    score: 0,
    size: 0,
    scaleFactor: 1,
    rotation: 0,
    claimedHives: 0,
    claimedFlowers: 0,
    pollenCollected: 0,
    kills: 0,
    timeAlive: null,
  });
  const [isConnected, setIsConnected] = useState(false);
  const socket = useRef(null);
  const { t } = useTranslation();
  const playClaimHiveEffectRef = useRef();
  const playClaimFlowerEffectRef = useRef();
  const playDeathEffectRef = useRef();
  const playX2scorePickupEffectRef = useRef();
  const playShieldPickupEffectRef = useRef();
  const playSeeAllPickupEffectRef = useRef();
  const playBoostPickupEffectRef = useRef();
  const playPollenPickupEffectRef = useRef();
  const playFirstPlaceEffectRef = useRef();
  const [roomID, setRoomID] = useState(null);
  const gameStateBuffer = useRef({ currentGameState: null, delayedGameState: null });
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language || 'en');
  const [renderDelay, setRenderDelay] = useState(100);
  const [localInputSequenceNumber, setLocalInputSequenceNumber] = useState(0);
  const [unprocessedInputs, setUnprocessedInputs] = useState([]);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [ping, setPing] = useState(0);
  const [roomIsFull, setRoomIsFull] = useState(false);
  const [isModalDisplayed, setIsModalDisplayed] = useState(true);
  const [showIsRoomFullModal, setShowRoomIsFullModal] = useState(false);
  const [justDied, setJustDied] = useState(false);
  const [audioToggle, setAudioToggle] = useState(false);
  const [policyToggle, setPolicyToggle] = useState(false);
  const [creditsToggle, setCreditsToggle] = useState(false);
  const [partnersToggle, setPartnersToggle] = useState(false);
  const [howToPlayToggle, setHowToPlayToggle] = useState(false);
  const [inviteFriendsToggle, setInviteFriendsToggle] = useState(false);
  const [disconnectedToggle, setDisconnectedToggle] = useState(false);
  // const [scaleFactor, setScaleFactor] = useState('1');
  const [bases, setBases] = useState();
  const [items, setItems] = useState();
  // const [kills, setKills] = useState(0);
  // const [score, setScore] = useState(0);
  // const [size, setSize] = useState(0);
  // const [timeAlive, setTimeAlive] = useState();
  const [isFirstPlace, setIsFirstPlace] = useState();
  const [scoreIsZero, setScoreIsZero] = useState(true);
  // const [claimedHives, setClaimedHives] = useState(0);
  // const [claimedFlowers, setClaimedFlowers] = useState(0);
  // const [pollenCollected, setPollenCollected] = useState(0);
  // const [moveY, setMoveY] = useState(Math.round(Math.random() * (9500 - 0) + 0));
  // const [moveX, setMoveX] = useState(Math.round(Math.random() * (9500 - 0) + 0));
  const [directionX, setDirectionX] = useState(1);
  const [directionY, setDirectionY] = useState(1);
  const [movementSpeed, setMovementSpeed] = useState(5);
  const [boostSpeed, setBoostSpeed] = useState(7);
  const [boostPress, setBoostPress] = useState(false);
  const [isDoubleTapped, setDoubleTapped] = useState(false);
  const [lastTapTime, setLastTapTime] = useState(null);
  // const [collidingWith, setCollidingWith] = useState();
  // const [rotation, setRotation] = useState(0);
  // const [username, setUsername] = useState('');
  // const [shieldItem, setShieldItem] = useState(false);
  // const [seeAllItem, setSeeAllItem] = useState(false);
  const [playerImage] = useState([honeybee, bumblebee, wasp, honeybeeQueen, bumblebeeQueen, waspQueen]);
  const [characterSelectImage] = useState([honeybeeCharacter, bumblebeeCharacter, waspCharacter]);
  const [currentImgIndex, setCurrentImgIndex] = useState(0);
  const [playerCharacters] = useState(['honeybee', 'bumblebee', 'wasp']);
  const [players, setPlayers] = useState([]);
  const [audioContext] = useState(() => new (window.AudioContext || window.webkitAudioContext)());
  const [audioBuffer, setAudioBuffer] = useState(null);
  const [audioSource, setAudioSource] = useState(null);
  const [gainNode] = useState(() => audioContext.createGain());
  const [clickEffectBuffer, setClickEffectBuffer] = useState(null);
  const [x2scorePickupEffectBuffer, setX2scorePickupEffectBuffer] = useState(null);
  const [shieldPickupEffectBuffer, setShieldPickupEffectBuffer] = useState(null);
  const [seeAllPickupEffectBuffer, setSeeAllPickupEffectBuffer] = useState(null);
  const [boostPickupEffectBuffer, setBoostPickupEffectBuffer] = useState(null);
  const [pollenPickupEffectBuffer, setPollenPickupEffectBuffer] = useState(null);
  const [firstPlaceEffectBuffer, setFirstPlaceEffectBuffer] = useState(null);
  const [deathEffectBuffer, setDeathEffectBuffer] = useState(null);
  const [claimHiveEffectBuffer, setClaimHiveEffectBuffer] = useState(null);
  const [claimFlowerEffectBuffer, setClaimFlowerEffectBuffer] = useState(null);
  const [inviteCode, setInviteCode] = useState('https://hibee.io/testlink');
  const [adCompleted, setAdCompleted] = useState(false);
  const [servers, setServers] = useState([
    { name: 'North America', url: 'https://server-us-east.hibee.io' },
    { name: 'Europe', url: 'https://server-eu-germany.hibee.io' },
    { name: 'India', url: 'https://server-as-india.hibee.io' },
    { name: 'Asia', url: 'https://server-as-singapore.hibee.io' },
    { name: 'Australia', url: 'https://server-oc-australia.hibee.io' },
  ]);
  const [selectedServer, setSelectedServer] = useState(null);

  useEffect(() => {
    function pingServer(server) {
      return new Promise((resolve) => {
        const start = new Date().getTime();
        fetch(server.url, { mode: 'no-cors' })
          .then(() => {
            const latency = new Date().getTime() - start;
            resolve({ server, latency });
          })
          .catch(() => {
            resolve({ server, latency: Infinity });
          });
      });
    }
    
    function findFastestServer() {
      const pingPromises = servers.map(pingServer);
      return new Promise((resolve) => {
        pingPromises.forEach(promise => promise.then(result => resolve(result)));
      });
    }

    findFastestServer().then((result) => {
      console.log('Fastest server:', result.server);
      setSelectedServer(result.server);
    });
  }, []);

  useEffect(() => {
    if (selectedServer) {
      socket.current = io(selectedServer.url);
      // setSocket(io(selectedServer.url));
      console.log('Connecting to', socket.current);
    }
  }, [selectedServer]);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const getPing = () => {
    if (!socket.current || socket.current && !socket.current.connected) return;
    socket.current.emit('ping');
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  useEffect(() => {
    window.aiptag.cmd.player.push(function() {
      window.aiptag.adplayer = new window.aipPlayer({
        AD_WIDTH: 960,
        AD_HEIGHT: 540,
        AD_DISPLAY: 'fullscreen', //default, fullscreen, center, fill
        LOADING_TEXT: 'loading advertisement',
        PREROLL_ELEM: function(){return document.getElementById('preroll')},
        AIP_COMPLETE: function (evt)  {
          /*******************
           ***** WARNING *****
            *******************
            Please do not remove the PREROLL_ELEM
            from the page, it will be hidden automaticly.
          */
          console.log("Preroll Ad Completed: " + evt);
          setAdCompleted(true);
          console.log(adCompleted);
        }
      });
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getPing();
      if (socket.current && socket.current.connected) {
        setIsConnected(true);
      } else {
        setIsConnected(false);
      }
    }, 500);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const roomIdFromUrl = urlParams.get('roomID');
    if (roomIdFromUrl) {
      setRoomID(roomIdFromUrl);
    }
  }, []);

  const loadAudio = async () => {
    const response = await fetch(hibeeTheme);
    const arrayBuffer = await response.arrayBuffer();
    const buffer = await audioContext.decodeAudioData(arrayBuffer);
    setAudioBuffer(buffer);
  };

  useEffect(() => {
    loadAudio();
  }, []);

  const loadClickEffect = async () => {
    const response = await fetch(clickEffect);
    const arrayBuffer = await response.arrayBuffer();
    const buffer = await audioContext.decodeAudioData(arrayBuffer);
    setClickEffectBuffer(buffer);
  };

  useEffect(() => {
    loadClickEffect();
  }, []);

  const playClickEffect = () => {
    if (audioToggle) {
      if (clickEffectBuffer) {
        const source = audioContext.createBufferSource();
        source.buffer = clickEffectBuffer;
        source.connect(audioContext.destination);
        source.start(0);
      }
    }
  };

  const loadPollenPickupEffect = async () => {
    const response = await fetch(pollenPickupEffect);
    const arrayBuffer = await response.arrayBuffer();
    const buffer = await audioContext.decodeAudioData(arrayBuffer);
    setPollenPickupEffectBuffer(buffer);
  };

  useEffect(() => {
    loadPollenPickupEffect();
  }, []);

  const playPollenPickupEffect = () => {
    if (audioToggle) {
      if (pollenPickupEffectBuffer) {
        const source = audioContext.createBufferSource();
        source.buffer = pollenPickupEffectBuffer;
        source.connect(audioContext.destination);
        source.start(0);
      }
    }
  };

  useEffect(() => {
    playPollenPickupEffectRef.current = playPollenPickupEffect;
  }, [playPollenPickupEffect]);

  // BoostEffect

  const loadBoostPickupEffect = async () => {
    const response = await fetch(boostPickupEffect);
    const arrayBuffer = await response.arrayBuffer();
    const buffer = await audioContext.decodeAudioData(arrayBuffer);
    setBoostPickupEffectBuffer(buffer);
  };

  useEffect(() => {
    loadBoostPickupEffect();
  }, []);

  const playBoostPickupEffect = () => {
    if (audioToggle) {
      if (boostPickupEffectBuffer) {
        const source = audioContext.createBufferSource();
        source.buffer = boostPickupEffectBuffer;
        source.connect(audioContext.destination);
        source.start(0);
      }
    }
  };

  useEffect(() => {
    playBoostPickupEffectRef.current = playBoostPickupEffect;
  }, [playBoostPickupEffect]);

  // SeeAll

  const loadSeeAllPickupEffect = async () => {
    const response = await fetch(seeAllPickupEffect);
    const arrayBuffer = await response.arrayBuffer();
    const buffer = await audioContext.decodeAudioData(arrayBuffer);
    setSeeAllPickupEffectBuffer(buffer);
  };

  useEffect(() => {
    loadSeeAllPickupEffect();
  }, []);

  const playSeeAllPickupEffect = () => {
    if (audioToggle) {
      if (seeAllPickupEffectBuffer) {
        const source = audioContext.createBufferSource();
        source.buffer = seeAllPickupEffectBuffer;
        source.connect(audioContext.destination);
        source.start(0);
      }
    }
  };

  useEffect(() => {
    playSeeAllPickupEffectRef.current = playSeeAllPickupEffect;
  }, [playSeeAllPickupEffect]);

  // Shield

  const loadShieldPickupEffect = async () => {
    const response = await fetch(shieldPickupEffect);
    const arrayBuffer = await response.arrayBuffer();
    const buffer = await audioContext.decodeAudioData(arrayBuffer);
    setShieldPickupEffectBuffer(buffer);
  };

  useEffect(() => {
    loadShieldPickupEffect();
  }, []);

  const playShieldPickupEffect = () => {
    if (audioToggle) {
      if (shieldPickupEffectBuffer) {
        const source = audioContext.createBufferSource();
        source.buffer = shieldPickupEffectBuffer;
        source.connect(audioContext.destination);
        source.start(0);
      }
    }
  };

  useEffect(() => {
    playShieldPickupEffectRef.current = playShieldPickupEffect;
  }, [playShieldPickupEffect]);

  // X2score

  const loadX2scorePickupEffect = async () => {
    const response = await fetch(x2scorePickupEffect);
    const arrayBuffer = await response.arrayBuffer();
    const buffer = await audioContext.decodeAudioData(arrayBuffer);
    setX2scorePickupEffectBuffer(buffer);
  };

  useEffect(() => {
    loadX2scorePickupEffect();
  }, []);

  const playX2scorePickupEffect = () => {
    if (audioToggle) {
      if (x2scorePickupEffectBuffer) {
        const source = audioContext.createBufferSource();
        source.buffer = x2scorePickupEffectBuffer;
        source.connect(audioContext.destination);
        source.start(0);
      }
    }
  };

  useEffect(() => {
    playX2scorePickupEffectRef.current = playX2scorePickupEffect;
  }, [playX2scorePickupEffect]);

  // Death

  const loadDeathEffect = async () => {
    const response = await fetch(deathEffect);
    const arrayBuffer = await response.arrayBuffer();
    const buffer = await audioContext.decodeAudioData(arrayBuffer);
    setDeathEffectBuffer(buffer);
  };

  useEffect(() => {
    loadDeathEffect();
  }, []);

  const playDeathEffect = () => {
    if (audioToggle) {
      if (deathEffectBuffer) {
        const source = audioContext.createBufferSource();
        source.buffer = deathEffectBuffer;
        source.connect(audioContext.destination);
        source.start(0);
      }
    }
  };

  useEffect(() => {
    playDeathEffectRef.current = playDeathEffect;
  }, [playDeathEffect]);

  // ClaimHive

  const loadClaimHiveEffect = async () => {
    const response = await fetch(claimHiveEffect);
    const arrayBuffer = await response.arrayBuffer();
    const buffer = await audioContext.decodeAudioData(arrayBuffer);
    setClaimHiveEffectBuffer(buffer);
  };

  useEffect(() => {
    loadClaimHiveEffect();
  }, []);

  const playClaimHiveEffect = () => {
    if (audioToggle) {
      if (claimHiveEffectBuffer) {
        const source = audioContext.createBufferSource();
        source.buffer = claimHiveEffectBuffer;
        source.connect(audioContext.destination);
        source.start(0);
      }
    }
  };

  useEffect(() => {
    playClaimHiveEffectRef.current = playClaimHiveEffect;
  }, [playClaimHiveEffect]);

  // ClaimFlower

  const loadClaimFlowerEffect = async () => {
    const response = await fetch(claimFlowerEffect);
    const arrayBuffer = await response.arrayBuffer();
    const buffer = await audioContext.decodeAudioData(arrayBuffer);
    setClaimFlowerEffectBuffer(buffer);
  };

  useEffect(() => {
    loadClaimFlowerEffect();
  }, []);

  const playClaimFlowerEffect = () => {
    if (audioToggle) {
      if (claimFlowerEffectBuffer) {
        const source = audioContext.createBufferSource();
        source.buffer = claimFlowerEffectBuffer;
        source.connect(audioContext.destination);
        source.start(0);
      }
    }
  };

  useEffect(() => {
    playClaimFlowerEffectRef.current = playClaimFlowerEffect;
  }, [playClaimFlowerEffect]);

  // FirstPlace

  const loadFirstPlaceEffect = async () => {
    const response = await fetch(firstPlaceEffect);
    const arrayBuffer = await response.arrayBuffer();
    const buffer = await audioContext.decodeAudioData(arrayBuffer);
    setFirstPlaceEffectBuffer(buffer);
  };

  useEffect(() => {
    loadFirstPlaceEffect();
  }, []);

  const playFirstPlaceEffect = () => {
    if (audioToggle) {
      if (pollenPickupEffectBuffer) {
        const source = audioContext.createBufferSource();
        source.buffer = firstPlaceEffectBuffer;
        source.connect(audioContext.destination);
        source.start(0);
      }
    }
  };

  useEffect(() => {
    playFirstPlaceEffectRef.current = playFirstPlaceEffect;
  }, [playFirstPlaceEffect]);

  useEffect(() => {
    if (audioBuffer) {
      if (audioToggle) {
        if (!audioSource) {
          const source = audioContext.createBufferSource();
          source.buffer = audioBuffer;
          source.loop = true;
          source.connect(gainNode);
          gainNode.connect(audioContext.destination);
          source.start(0);
          setAudioSource(source);
        }
        gainNode.gain.setValueAtTime(1, audioContext.currentTime);
      } else if (audioSource) {
        gainNode.gain.setValueAtTime(0, audioContext.currentTime);
      }
    }
  }, [audioToggle, audioBuffer]);

  useEffect(() => {
    if (isFirstPlace) {
      playFirstPlaceEffectRef.current();
    }
  }, [isFirstPlace]);

  const handleFullscreen = () => {
    playClickEffect();
    if (!isFullscreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
    setIsFullscreen(!isFullscreen);
  };

  // Watch for fullscreenchange
  useEffect(() => {
    function onFullscreenChange() {
      setIsFullscreen(Boolean(document.fullscreenElement));
    }
    document.addEventListener('fullscreenchange', onFullscreenChange);
    return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
  }, []);

  const handleTouchStart = useCallback(() => {
    const currentTime = new Date().getTime();
    if (lastTapTime && currentTime - lastTapTime < 300) {
      setDoubleTapped(true);
      setBoostPress(true);
    }
    setLastTapTime(currentTime);
  }, [lastTapTime]);

  const handleTouchEnd = useCallback(() => {
    setDoubleTapped(false);
    setBoostPress(false);
  }, []);

  useEffect(() => {
    if (isDoubleTapped) {
      console.log('Double Tap Detected');
    }
  }, [isDoubleTapped]);

  useEffect(() => {
    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [handleTouchStart, handleTouchEnd]);

  const convertDataToArray = (data) => {
    const newData = new Map(JSON.parse(data));
    return Array.from(newData, ([key, value]) => ({ [key]: value }));
  };

  useEffect(() => {
    if (!isConnected) {
      console.log('Connecting to server...', socket.current);
    } else {
      console.log('success!!!', socket.current);

    const handleTooManyConnectedUsers = () => {
      setRoomIsFull(true);
    };

    const handleConnect = () => {
      console.log('Connected to server :)');
    };

    const handlePong = (start) => {
      const end = Date.now();
      setPing(end - start);
    };

    const handleBaseLocations = (data) => {
      setBases(convertDataToArray(data));
    };

    const handleItemLocations = (data) => {
      setItems(convertDataToArray(data));
    };

    const handleUpdateState = (data) => {
      // Remove acknowledged inputs
      const newUnprocessedInputs = unprocessedInputs.filter(input => input.sequenceNumber > data.lastProcessedInputSequenceNumber);
      setUnprocessedInputs(newUnprocessedInputs);
  
      // Server reconciliation: reapply remaining unprocessed inputs
      newUnprocessedInputs.forEach(({ rotation }) => {
        applyLocalRotation(rotation);
      });
  
      const convertedBases = convertDataToArray(data.sendBases);
      const convertedItems = convertDataToArray(data.sendItems);
  
      setBases(convertedBases);
      setItems(convertedItems);
  
      const receivedTimestamp = Date.now();
  
      // Shift the game states in the buffer
      gameStateBuffer.current.delayedGameState = gameStateBuffer.current.currentGameState;
      gameStateBuffer.current.currentGameState = {
        arrayDataPlayers: convertDataToArray(data.sendPlayers),
        arrayDataBases: convertedBases,
        arrayDataItems: convertedItems,
        receivedTimestamp,
      };
    };

    const handleItemCollision = (data) => {
      console.log(data);
      if (data === 'pollen') {
        playPollenPickupEffectRef.current();
      }
      if (data === 'see-all') {
        playSeeAllPickupEffectRef.current();
      }
      if (data === 'boost') {
        playBoostPickupEffectRef.current();
      }
      if (data === 'x2score') {
        playX2scorePickupEffectRef.current();
      }
      if (data === 'shield') {
        console.log('shield');
        playShieldPickupEffectRef.current();
      }
    };

    const handleBaseClaimed = (data) => {
      const claimEffects = {
        'flower': playClaimFlowerEffectRef.current,
        'hive': playClaimHiveEffectRef.current,
      };

      if (claimEffects[data]) {
        claimEffects[data]();
      }
    };

    const handleCollision = (data) => {
      if (data === 'die') {
        playDeathEffectRef.current();
        socket.current.disconnect();
        setJustDied(true);
        setIsModalDisplayed(true);
        setDisconnectedToggle(false);
        setPlayers([]);
        setBases([]);
        // setMoveX(Math.round(Math.random() * (9500 - 0) + 0));
        // setMoveY(Math.round(Math.random() * (9500 - 0) + 0));
        // setRotation(0);
        // setSize(50);
        setLocalPlayer(prev => {
          return {
            ...prev,
            size: 50,
            moveX: Math.round(Math.random() * (9500 - 0) + 0),
            moveY: Math.round(Math.random() * (9500 - 0) + 0),
            rotation: 0,
          }
        });
        socket.current.connect();
      } else {
        playPollenPickupEffectRef.current();
      }
    };

    const handleInviteLinkCreated = (data) => {
      console.log('Invite link created: ', data);
      setInviteCode(`https://hibee.io/?roomID=${data}`);
    };

    const handleDisconnect = () => {
      console.log('Disconnected from server :(');
      setDisconnectedToggle(true);
    };

    socket.current.on('tooManyConnectedUsers', handleTooManyConnectedUsers);
    socket.current.on('connect', handleConnect);
    socket.current.on('pong', handlePong);
    socket.current.on('baseLocations', handleBaseLocations);
    socket.current.on('itemLocations', handleItemLocations);
    socket.current.on('updateState', handleUpdateState);
    socket.current.on('itemCollision', handleItemCollision);
    socket.current.on('baseClaimed', handleBaseClaimed);
    socket.current.on('collision', handleCollision);
    socket.current.on('inviteLinkCreated', handleInviteLinkCreated);
    socket.current.on('disconnect', handleDisconnect);

    return () => {
      socket.current.off('tooManyConnectedUsers', handleTooManyConnectedUsers);
      socket.current.off('connect', handleConnect);
      socket.current.off('pong', handlePong);
      socket.current.off('baseLocations', handleBaseLocations);
      socket.current.off('itemLocations', handleItemLocations);
      socket.current.off('updateState', handleUpdateState);
      socket.current.off('itemCollision', handleItemCollision);
      socket.current.off('baseClaimed', handleBaseClaimed);
      socket.current.off('collision', handleCollision);
      socket.current.off('inviteLinkCreated', handleInviteLinkCreated);
      socket.current.off('disconnect', handleDisconnect);
    };
  }
  }, [isConnected, socket.current]);

  const moveBackgroundMenu = useCallback(() => {
    if (isModalDisplayed) {
      if (localPlayer.moveX < 9000 && directionX === 1) {
        // setMoveX(prevMoveX => prevMoveX + 1);
        setLocalPlayer(prev => {
          return {
            ...prev,
            moveX: prev.moveX + 1,
          }
        })
      } else if (localPlayer.moveX > 500 && directionX === -1) {
        // setMoveX(prevMoveX => prevMoveX - 1);
        setLocalPlayer(prev => {
          return {
            ...prev,
            moveX: prev.moveX - 1,
          }
        })
      } else {
        setDirectionX(prevDirectionX => -prevDirectionX);
      }

      if (localPlayer.moveY < 9000 && directionY === 1) {
        // setMoveY(prevMoveY => prevMoveY + 1);
        setLocalPlayer(prev => {
          return {
            ...prev,
            moveY: prev.moveY + 1,
          }
        })
      } else if (localPlayer.moveY > 500 && directionY === -1) {
        // setMoveY(prevMoveY => prevMoveY - 1);
        setLocalPlayer(prev => {
          return {
            ...prev,
            moveY: prev.moveY - 1,
          }
        })
      } else {
        setDirectionY(prevDirectionY => -prevDirectionY);
      }
    }
  }, [isModalDisplayed, localPlayer, directionX, directionY]);

  // Move menu camera around
  useEffect(() => {
    let animationFrameId;
    function animationLoop() {
      moveBackgroundMenu();
      animationFrameId = window.requestAnimationFrame(animationLoop);
    }
    animationFrameId = window.requestAnimationFrame(animationLoop);
    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [moveBackgroundMenu]);

  // Set local veriables to server values
  useEffect(() => {
    if (!socket.current || !socket.current.connected) return;
  
    const playerId = socket.current.id;
    const player = players.find(p => Object.keys(p)[0] === playerId);
  
    if (player) {
      const playerData = Object.values(player)[0];

      setLocalPlayer(prev => {
        return {
          ...prev,
          shieldItem: playerData.shieldItem,
          seeAllItem: playerData.seeAllItem,
          score: playerData.score,
          size: playerData.size,
          claimedHives: playerData.claimedHives,
          claimedFlowers: playerData.claimedFlowers,
          pollenCollected: playerData.pollenCollected,
          collidingWith: playerData.collidingWith,
          kills: playerData.kills,
          timeAlive: playerData.timeAlive,
          isFirstPlace: playerData.isFirstPlace,
          scaleFactor: playerData.scaleFactor,
          moveX: playerData.pos[0],
          moveY: playerData.pos[1],
        }
      });
      
      setIsFirstPlace(playerData.isFirstPlace);
    }
  }, [players]);

  // Check if modal is displayed
  useEffect(() => {
    isModalDisplayed && (document.body.style.overflow = 'hidden');
    !isModalDisplayed && (document.body.style.overflow = 'unset');
  }, [isModalDisplayed]);

  useEffect(() => {
    if (!socket.current || socket.current && !socket.current.connected) return;
    socket.current.emit('boosting', boostPress, localPlayer.score, scoreIsZero);
  }, [boostPress, scoreIsZero]);

  useEffect(() => {
    if (localPlayer.score > 0) {
      setScoreIsZero(false);
    } else {
      setScoreIsZero(true);
    }
  }, [localPlayer]);

  const checkMovement = useCallback(() => {
    if (!socket.current || socket.current && !socket.current.connected) return;
    if (localPlayer.rotation !== 0) {
      // Increment sequence number and store the input
      setLocalInputSequenceNumber(localInputSequenceNumber + 1);
      setUnprocessedInputs([...unprocessedInputs, { rotation: localPlayer.rotation, sequenceNumber: localInputSequenceNumber }]);

      socket.current.emit('rotationUpdate', { angle: localPlayer.rotation, sequenceNumber: localInputSequenceNumber });

      // Client prediction: apply the local rotation
      applyLocalRotation(localPlayer.rotation);
    }
  }, [localPlayer, localInputSequenceNumber, unprocessedInputs]);

  function lerp(a, b, t) {
    return a + (b - a) * t;
  }

  function interpolateGameState(prevState, nextState, t) {
    // Implement interpolation logic for players, bases, and items
    // Use the lerp function to interpolate the positions or other relevant properties

    const prevPlayers = prevState.arrayDataPlayers;
    const nextPlayers = nextState.arrayDataPlayers;

    const interpolatedPlayers = prevPlayers.map((player, index) => {
      const nextPlayer = nextPlayers[index];
      if (nextPlayer) {
        const objectData = Object.values(player)[0];
        const objectId = Object.keys(player)[0];
        const nextData = Object.values(nextPlayer)[0];
        const nextId = Object.keys(nextPlayer)[0];
        // Interpolate the properties of the player objects, e.g., position, rotation, etc.
        // For example, if player objects have x and y properties:
        const x = lerp(objectData.pos[0], nextData.pos[0], t);
        const y = lerp(objectData.pos[1], nextData.pos[1], t);

        return { [objectId]: { ...objectData, pos: [x, y] } }; // Return the interpolated player object
      } else {
        return player;
      }
    });
    return interpolatedPlayers;
  }

  // Movement calculation
  useEffect(() => {
    let animationFrameId;
    
    function animationLoop() {
      checkMovement();
  
      const currentTime = Date.now();
  
      const prevState = gameStateBuffer.current.delayedGameState;
      const nextState = gameStateBuffer.current.currentGameState;
  
      if (prevState && nextState) {
        const delta = nextState.receivedTimestamp - prevState.receivedTimestamp;
        const elapsedTime = currentTime - prevState.receivedTimestamp - renderDelay; 
  
        // Ensure t is within the range [0, 1] to avoid extrapolation
        const t = Math.max(0, Math.min(1, elapsedTime / delta));
  
        const interpolatedGameState = interpolateGameState(prevState, nextState, t);
        // Update the game state with the interpolated game state
        setPlayers(interpolatedGameState);
      }
  
      animationFrameId = window.requestAnimationFrame(animationLoop);
    }
  
    animationFrameId = window.requestAnimationFrame(animationLoop);
    
    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [localPlayer, isModalDisplayed]);

  // Rotation calculation
  useEffect(() => {
    const handleEvent = (event) => {
      let x, y;

      if (event.touches) {
        // Commented out to allow scrolling on mobile inside popup modals
        if (!isModalDisplayed) {
          event.preventDefault();
        }
        x = event.touches[0].pageX;
        y = event.touches[0].pageY;
      } else {
        x = event.clientX;
        y = event.clientY;
      }

      updateRotation(x, y);
    };

    const eventOptions = { passive: false };
    const events = ["mousemove", "touchmove"];

    events.forEach((eventName) =>
      window.addEventListener(eventName, handleEvent, eventOptions)
    );

    return () => {
      events.forEach((eventName) =>
        window.removeEventListener(eventName, handleEvent, eventOptions)
      );
    };
  }, [isModalDisplayed]);

  useEffect(() => {
    const handleSpaceEvent = (event) => {
      if (event.code === "Space") {
        setBoostPress(event.type === "keydown");
      }
    };

    const events = ["keydown", "keyup"];

    events.forEach((eventName) =>
      window.addEventListener(eventName, handleSpaceEvent)
    );

    return () => {
      events.forEach((eventName) =>
        window.removeEventListener(eventName, handleSpaceEvent)
      );
    };
  }, []);

const applyLocalRotation = (rotation) => {
  if (!socket.current || !socket.current.connected) return;

  const playerId = socket.current.id;
  const player = players[playerId];
  if (!player) return;

  let playerSpeed = player.boosting ? boostSpeed : movementSpeed;
  if (player.boostItem) {
    playerSpeed *= 1.8;
  }

  const xMovement = playerSpeed * Math.cos((rotation - 90) * (Math.PI / 180));
  const yMovement = playerSpeed * Math.sin((rotation - 90) * (Math.PI / 180));

  const xPos = Math.min(Math.max(1, player.pos[0] + xMovement), 9915);
  const yPos = Math.min(Math.max(1, player.pos[1] + yMovement), 9923);

  // Update the local player's position
  setPlayers({
    ...players,
    [playerId]: { ...player, pos: [xPos, yPos] },
  });
};

  const handlePlayClick = (e, startingScore=0) => {
    if (!socket.current || socket.current && !socket.current.connected) return;
    playClickEffect();
    if (localPlayer.username.length >= 1 && !roomIsFull) {
      socket.current.emit('playerConnected', localPlayer.username, localPlayer.moveX, localPlayer.moveY, startingScore);
      setIsModalDisplayed(false);
      setDisconnectedToggle(false);
    } else if (roomIsFull) {
      e.preventDefault();
      setShowRoomIsFullModal(true);
    }
  }

  const updateRotation = (mouseX, mouseY) => {
    // Calculate the angle between the mouse position and the center of the player
    let angle = Math.atan2(mouseX - (window.innerWidth / 2), - (mouseY - (window.innerHeight / 2))) * (180 / Math.PI);
    // setRotation(angle);
    setLocalPlayer(prev => {
      return {
        ...prev,
        rotation: angle,
      }
    });
  }

  const handleLeftCharSelectClick = (() => {
    if (!socket.current || socket.current && !socket.current.connected) return;
    playClickEffect();
    const newIndex = currentImgIndex === 0 ? playerCharacters.length - 1 : currentImgIndex - 1;
    const selectedCharacter = playerCharacters[newIndex];
    setCurrentImgIndex(newIndex);
    socket.current.emit('selectedCharacter', selectedCharacter);
  });

  const handleRightCharSelectClick = (() => {
    if (!socket.current || socket.current && !socket.current.connected) return;
    playClickEffect();
    const newIndex = currentImgIndex === playerCharacters.length - 1 ? 0 : currentImgIndex + 1;
    const selectedCharacter = playerCharacters[newIndex];
    setCurrentImgIndex(newIndex);
    socket.current.emit('selectedCharacter', selectedCharacter);
  });

  function calculateUniformScaleFactor(baseWidth, baseHeight, scaleFactor) {
    const screenWidth = (window.innerWidth);
    const screenHeight = (window.innerHeight);
    const widthRatio = screenWidth / baseWidth;
    const heightRatio = screenHeight / baseHeight;
    const minRatio = Math.min(widthRatio, heightRatio);

    if (screenWidth <= 600) {
      return (minRatio * Number(scaleFactor)) * 2.25;
    }

    return minRatio * Number(scaleFactor);
  }

  const calculateScale = (width, height, factor) =>
    `${calculateUniformScaleFactor(width, height, factor)}`;

  // Player Component Creator
  const renderPlayer = (playerData, isCurrentUser = false) => (
    <Player
      socket={socket.current}
      playerImage={
        isCurrentUser
          ? isFirstPlace
            ? playerImage[currentImgIndex + 3]
            : playerImage[currentImgIndex]
          : playerData.isFirstPlace
            ? playerImage[playerCharacters.indexOf(playerData.playerImage) + 3]
            : playerImage[playerCharacters.indexOf(playerData.playerImage)]
      }
      shieldItem={isCurrentUser ? localPlayer.shieldItem : playerData.shieldItem}
      seeAllItem={isCurrentUser ? localPlayer.seeAllItem : playerData.seeAllItem}
      username={isCurrentUser ? localPlayer.username : playerData.username}
      moveY={isCurrentUser ? localPlayer.moveY : playerData.pos[1]}
      moveX={isCurrentUser ? localPlayer.moveX : playerData.pos[0]}
      collidingWith={isCurrentUser ? localPlayer.collidingWith : playerData.collidingWith}
      score={isCurrentUser ? localPlayer.score : playerData.score}
      size={isCurrentUser ? localPlayer.size : playerData.size}
      rotation={isCurrentUser ? localPlayer.rotation : playerData.rotation}
      claimedHives={isCurrentUser ? localPlayer.claimedHives : playerData.claimedHives}
      claimedFlowers={isCurrentUser ? localPlayer.claimedFlowers : playerData.claimedFlowers}
      pollenCollected={isCurrentUser ? localPlayer.pollenCollected : playerData.pollenCollected}
      kills={isCurrentUser ? localPlayer.kills : playerData.kills}
      timeAlive={isCurrentUser ? localPlayer.timeAlive : playerData.timeAlive}
      isFirstPlace={isCurrentUser ? isFirstPlace : playerData.isFirstPlace}
    />
  );

  const scaledFactor = calculateScale(1240, 720, localPlayer.scaleFactor);

  // console.log(players)
  // console.log(bases)
  // console.log(items)
  // players.forEach((playerId) => {
  //   if(!Object.keys(playerId)[0].includes('bot')) {
  //     console.log(Object.entries(playerId)[0][1].size);
  //   }
  // });

  return (
    <div className="App">
      <AdInPlayVideoAd />
      {isModalDisplayed && (
        <LoginModal
          socket={socket.current}
          servers={servers}
          selectedServer={selectedServer}
          setSelectedServer={setSelectedServer}
          adCompleted={adCompleted}
          setAdCompleted={setAdCompleted}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          i18n={i18n}
          playClickEffect={playClickEffect}
          inviteCode={inviteCode}
          handleLeftCharSelectClick={handleLeftCharSelectClick}
          handleRightCharSelectClick={handleRightCharSelectClick}
          playerImage={characterSelectImage[currentImgIndex]}
          showIsRoomFullModal={showIsRoomFullModal}
          setShowRoomIsFullModal={setShowRoomIsFullModal}
          handleFullscreen={handleFullscreen}
          score={localPlayer.score}
          timeAlive={localPlayer.timeAlive}
          isFirstPlace={isFirstPlace}
          claimedHives={localPlayer.claimedHives}
          claimedFlowers={localPlayer.claimedFlowers}
          kills={localPlayer.kills}
          isModalDisplayed={isModalDisplayed}
          justDied={justDied}
          creditsToggle={creditsToggle}
          setCreditsToggle={setCreditsToggle}
          setPartnersToggle={setPartnersToggle}
          partnersToggle={partnersToggle}
          howToPlayToggle={howToPlayToggle}
          setHowToPlayToggle={setHowToPlayToggle}
          inviteFriendsToggle={inviteFriendsToggle}
          setInviteFriendsToggle={setInviteFriendsToggle}
          policyToggle={policyToggle}
          setPolicyToggle={setPolicyToggle}
          audioToggle={audioToggle}
          setAudioToggle={setAudioToggle}
          username={localPlayer.username}
          // setUsername={setUsername}
          setLocalPlayer={setLocalPlayer}
          handlePlayClick={handlePlayClick}
        />
      )}
      <div className="camera">
        <div
          className="game-board"
          style={{
            scale: scaledFactor,
            transformOrigin: "top left",
            backgroundImage: `url(${backgroundImage})`,
            top: -localPlayer.moveY * scaledFactor + (window.innerHeight / 2 - 46),
            left: -localPlayer.moveX * scaledFactor + (window.innerWidth / 2 - 16),
          }}
        >
          {players &&
            players
              .filter((el) => Object.keys(el)[0] !== socket.current.id)
              .map((player) => renderPlayer(Object.entries(player)[0][1]))}
          {!isModalDisplayed && renderPlayer(null, true)}
          {bases &&
            bases.map((base) => (
              <Base
                backgroundImage={Object.values(base)[0].backgroundImage}
                type={Object.values(base)[0].type}
                beingClaimed={Object.values(base)[0].isBeingCaptured}
                claimed={Object.values(base)[0].claimedBy !== 'N/A'}
                location={Object.values(base)[0].pos} socket={socket.current}
                userId={Object.values(base)[0].userId}
                claimedBy={Object.values(base)[0].claimedBy}
              />
            ))}
          {items &&
            items.map((item) => {
              const data = Object.values(item)[0];
              const key = Object.keys(item)[0];
              return (
                <Item
                  key={key}
                  backgroundImage={data.backgroundImage}
                  type={data.type}
                  location={data.pos}
                  socket={socket.current}
                  userId={data.userId}
                />
              );
            })}
        </div>
      </div>
      {players && !isModalDisplayed && (
        <>
          <Leaderboard players={players} socket={socket.current} />
          <div className="modal-settings-container">
            <PlayerCount playerCount={players.length} />
            <ToggleAudio
              playClickEffect={playClickEffect}
              gameplayDisplay={true}
              audioToggle={audioToggle}
              setAudioToggle={setAudioToggle}
            />
            <div className="fullscreen-toggle" onClick={handleFullscreen}>
              <MdOutlineFullscreen />
            </div>
          </div>
          <MiniMap
            isFirstPlace={isFirstPlace}
            ping={Math.abs(ping)}
            socket={socket.current}
            seeAllItem={localPlayer.seeAllItem}
            width={width}
            items={items}
            bases={bases}
            players={players}
            playerPosition={[localPlayer.moveX, localPlayer.moveY]}
          />
          <ScoreDisplay score={localPlayer.score} />
          {disconnectedToggle && !isModalDisplayed && (
            <InfoModal
              disableCloseButton={true}
              setToggle={setDisconnectedToggle}
              title="Disconnected"
              text={
                <button
                  className="reconnect-button"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  reconnect
                </button>
              }
            />
          )}
        </>
      )}
    </div>
  );
}

export default App;
